import "./Nav.css";

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaArrowRight, FaChevronDown, FaChevronUp } from "react-icons/fa";

import logo from "../../img/bolt.svg";
import hamburger from "../../img/hamburger.svg";
import Link from "../Link";

const CURRENT_VERSION = process.env.GATSBY_GIT_BRANCH;

const Nav = ({ items, currentPage, isExpanded, setIsExpanded, versions }) => {
  const signInWrapperClass = classNames(
    {
      "hidden lg:block": !isExpanded,
    },
    "px-6 py-6 border-t border-en-gray-200 text-right lg:text-left relative z-20 bg-white"
  );

  const headerClass = classNames(
    {
      "h-screen": isExpanded,
    },
    "bg-white nav-width flex flex-col justify-between fixed lg:relative shadow-md flex-shrink-0 z-10 lg:min-h-screen"
  );
  const navClass = classNames(
    {
      "": isExpanded,
      "hidden lg:block": !isExpanded,
    },
    "overflow-y-auto top-0 fixed h-screen nav-width py-24"
  );
  return (
    <header className={headerClass}>
      <div>
        <div className="flex justify-between items-center py-4 px-6 lg:pb-10 shadow-lg relative lg:shadow-none bg-white z-20">
          <Link href="/">
            <h1 className="font-bold text-xl text-en-gray-900 flex items-center">
              <img src={logo} className="h-12 pr-3" /> API
            </h1>
          </Link>
          <div className="flex">
            {versions && versions.length > 1 ? (
              <VersionDropdown versions={versions} />
            ) : (
              <div className="px-2 py-1 rounded bg-en-gray-100 text-sm font-bold">
                v1
              </div>
            )}
            <button
              className="ml-4 lg:hidden"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <img
                src={hamburger}
                alt={isExpanded ? "Close Menu" : "Open Menu"}
              />
            </button>
          </div>
        </div>
        <nav className={navClass}>
          {items &&
            items.length > 0 &&
            items.map((item, i) => (
              <NavItem key={i} currentPage={currentPage} {...item} />
            ))}
        </nav>
      </div>
      <div className={signInWrapperClass}>
        <Link
          className="font-bold text-en-gray-900"
          href="https://portal.getenpowered.com"
        >
          Sign in <FaArrowRight className="inline-block ml-2" />
        </Link>
      </div>
    </header>
  );
};

Nav.propTypes = {
  items: PropTypes.array,
  currentPage: PropTypes.string,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  versions: PropTypes.array,
};

const NavItem = ({ childItems, label, link, currentPage }) => {
  const isExpandedByDefault =
    childItems &&
    childItems.some((childItem) => childItem.link === "/" + currentPage);
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  return (
    <div className=" px-6 py-4 lg:py-3 border-b lg:border-0 border-en-gray-200">
      {childItems && childItems.length > 0 ? (
        <div>
          <button
            className="font-bold text-left text-en-gray-900 uppercase w-full flex justify-between items-center"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {label}
            {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          {isExpanded &&
            childItems &&
            childItems.map((childItem) => (
              <div
                className={`pl-4 pt-2 text-sm text-en-gray-900 ${
                  childItem.link === "/" + currentPage ? "font-bold" : ""
                }`}
                key={childItem.link}
              >
                <Link href={childItem.link}>{childItem.label}</Link>
              </div>
            ))}
        </div>
      ) : (
        <div>
          <Link className="font-bold uppercase text-en-gray-900" href={link}>
            {label}
          </Link>
        </div>
      )}
    </div>
  );
};

NavItem.propTypes = {
  childItems: PropTypes.array,
  label: PropTypes.string,
  link: PropTypes.string,
  currentPage: PropTypes.string,
};

const VersionDropdown = ({ versions }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center px-2 py-1 rounded bg-en-gray-100 text-sm font-bold"
      >
        <span className="pr-2">v1</span>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {isOpen && (
        <div className="fixed top-0 mt-16 rounded bg-white shadow">
          {versions.map((version) =>
            CURRENT_VERSION === version ? (
              <button
                key={version}
                className="block px-4 py-1 hover:bg-en-gray-100 text-sm font-bold"
                onClick={() => setIsOpen(!isOpen)}
              >
                {version}
              </button>
            ) : (
              <a
                className="block px-4 py-1 hover:bg-en-gray-100 text-sm font-bold"
                key={version}
                href={`/${version}`}
              >
                {version}
              </a>
            )
          )}
        </div>
      )}
    </div>
  );
};

VersionDropdown.propTypes = {
  versions: PropTypes.array,
};

export default Nav;
