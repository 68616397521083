import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Link = ({ as, href = "", children, ...props }) => {
  const El = /^https?:/.test(href) ? "a" : GatsbyLink;
  const linkProps = El === "a" ? { href, ...props } : { to: href, ...props };
  return <El {...linkProps}>{children}</El>;
};

Link.defaultProps = {
  as: "a",
};

Link.propTypes = {
  as: PropTypes.elementType,
  href: PropTypes.string,
};

export default Link;
