import "../layout.css";
import "@enpowered/ui/dist/index.css";

import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import { withPrefix } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Nav from "./Nav";
import useSiteMetadata from "./SiteMetadata";

const NavLink = ({ href, children, ...props }) =>
  href ? (
    <Link to={href} {...props}>
      {children}
    </Link>
  ) : (
    <a {...props}>{children}</a>
  );

NavLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

const TemplateWrapper = ({ children, meta = {} }) => {
  const { title, description } = useSiteMetadata();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const metaTitle = meta && meta.title ? `${title} | ${meta.title}` : title;
  const metaDescription = (meta && meta.description) || description;
  const metaImage =
    (meta && meta.image) || `${withPrefix("/")}img/og-image.png`;

  const menuData = useStaticQuery(graphql`
    query MenuQuery {
      markdownRemark(frontmatter: { configType: { eq: "menu" } }) {
        frontmatter {
          mainMenu {
            menuItems {
              link
              label
              childItems {
                link
                label
              }
            }
          }
          versions {
            label
          }
        }
      }
    }
  `);

  const { mainMenu, versions } = menuData.markdownRemark.frontmatter;
  const versionStrings = versions.map(({ label }) => label);

  const mainClass = classNames(
    {
      "overflow-y-hidden": isNavExpanded,
    },
    "lg:overflow-y-auto w-full bg-yellow-100 pt-12 lg:pt-0 min-h-screen"
  );

  const wrapperClass = classNames(
    {
      "overflow-y-hidden": isNavExpanded,
    },
    "lg:overflow-y-auto bg-yellow-100 lg:flex max-h-screen"
  );
  return (
    <div className={wrapperClass}>
      <Helmet>
        <html lang="en" />
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${withPrefix("/")}favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${withPrefix("/")}favicon-16x16.png`}
        />
        <link rel="manifest" href={`${withPrefix("/")}site.webmanifest`} />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}safari-pinned-tab.svg`}
          color="#454444"
        />
        <meta name="msapplication-TileColor" content="#f4f4f5" />
        <meta name="theme-color" content="#f4f4f5" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`https://www.getenpowered.com${metaImage}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <Nav
        items={mainMenu.menuItems}
        linkComponent={NavLink}
        className="bg-white border"
        loginURL="https://portal.getenpowered.com/"
        registerURL="https://portal.getenpowered.com/#/register"
      /> */}
      <Nav
        items={mainMenu.menuItems}
        versions={versionStrings}
        isExpanded={isNavExpanded}
        setIsExpanded={setIsNavExpanded}
        currentPage={meta.slug === "index" ? "" : meta.slug}
      />
      <main className={mainClass}>{children}</main>
    </div>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    slug: PropTypes.string,
  }),
};

export default TemplateWrapper;
